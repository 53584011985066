@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

html,
body {
  height: 100%;
  margin: 0;
  font-family: "NanumSquare", sans-serif;
  overflow-x: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.width100 {
  width: 100%;
}
.content {
  flex: 1;
  overflow: auto;
}

.d-none {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* 모든 하이퍼텍스트 밑줄 제거(리액트 Link 포함) */
a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .Spin {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 1310px;
  }
}

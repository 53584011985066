.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-row-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-row-nowrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-item {
  flex-basis: 300px;
}

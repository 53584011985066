@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap");

#myNav {
  background-color: rgba(0, 0, 0, 0.9); /* Black w/opacity */
  font-family: "Poppins", sans-serif;
}

.overlayNav {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  z-index: 1; /* Sit on top */

  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  width: 100%; /* 100% width */
  margin: auto;
  /* margin-top: 100px; */
}

/* The navigation links inside the overlay */
.overlay span {
  padding: 8px;
  text-decoration: none;
  /* Overlay 링크 글씨 크기 */
  font-size: clamp(0.5rem, 1vw, 2rem);
  color: #e2e2e2;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}
.overlay span.overlayheader {
  /* Overlay 헤더 글씨 크기 */
  font-size: clamp(0.8rem, 1.5vw, 3rem);
  margin: 3vw 1vw 2vw 2vw;
}

/* When you mouse over the navigation links, change their color */
.overlay span:not(.overlayheader):hover,
.overlay span:not(.overlayheader):focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay span {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

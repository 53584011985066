footer {
  width: 100%;
  justify-content: center;
  padding: 5px;
  background-color: #101f2e;
  /* background: linear-gradient(45deg, turquoise, hotpink, deeppink); */
  color: #fff;
  flex-direction: column;
}
footer h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-right: 2%;
  margin-left: 5%; */
}
footer p {
  margin: 0px;
  font-size: 50%;
}

footer span {
  margin-left: 0px;
  margin-right: 5px;
}

footer .links {
  margin-left: auto;
}
footer .links a {
  margin: 0px 10px;
  font-size: clamp(0.5rem, 0.8vw, 1rem);
}

.flex-container {
  display: flex;
}

.flex-item {
  flex: none;
  justify-content: space-around;
  /* flex: 0 0 auto */
}

.flex-item-gnb {
  margin-left: auto;
}

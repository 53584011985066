@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap");
@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css");

#nav {
  font-family: "NanumSquare", sans-serif;
}

nav {
  font-family: "NanumSquare", sans-serif;
  flex-grow: 1;
  position: relative;
}

/* Add a black background color to the top navigation */
nav .topnav {
  background-color: #0000;
  padding: 0px 10px;
  justify-content: space-between;
}

/* Style the links inside the navigation bar */
nav .topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  margin: 1vw 1vw;
  text-decoration: none;
  font-size: clamp(0.4rem, 0.9vw, 0.9rem);
  text-decoration: underline 0.15em rgba(255, 255, 255, 0);
  text-underline-offset: 0.2em;
}

/* Change the color of links on hover */
nav .topnav a:hover {
  text-decoration: underline 0.15em rgba(255, 255, 255, 1);
  transition: text-decoration-color 0.5s;
}

/* Add an active class to highlight the current page */
nav .topnav a.active {
  /* border-bottom-width: 1px;
    border-bottom-style: solid; */
  text-decoration: underline 0.15em rgba(255, 255, 255, 1);
  text-underline-offset: 30%;
  color: white;
}

/* Hide the link that should open and close the topnav on small screens */
nav .topnav .icon {
  display: none;
}

nav .topnav ul {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}

nav .topnav ul.navDropdown li {
  display: none;
  list-style: none;
}
nav .topnav li:hover > ul.navDropdown li {
  display: flex;
  flex: 1;
}
nav .topnav li > ul.navDropdown {
  position: absolute;
  display: flex;
  left: 0;
  top: 100%;
  width: 100%;
}

.Background1 {
  width: 100%;
  height: 20vh;
  background-image: url(../../img/backgrounds/suitman.jpg);
  background-size: cover;
}

.Background2 {
  width: 100%;
  height: 25vh;
  background-image: url(../../img/backgrounds/BMWkorea2.jpg);
  background-size: cover;
  background-position: bottom 65% left;
}


.Background3 {
  width: 100%;
  height: 25vh;
  background-image: url(../../img/backgrounds/BMWkorea.jpg);
  background-size: cover;
}

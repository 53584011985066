@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&display=swap");

@media only screen and (max-width: 768px) {
  /* Add a black background color to the top navigation */
  nav .topnav {
    background-color: #0000;
    overflow: hidden;
    padding: 0px 10px;
  }

  /* Style the links inside the navigation bar */
  nav .topnav a {
    display: none;
  }

  /* Change the color of links on hover */
  nav .topnav a:hover {
    color: #bebebe;
  }

  /* Add an active class to highlight the current page */
  nav .topnav a.active {
    /* border-bottom-width: 1px;
        border-bottom-style: solid; */
    text-decoration: underline;
    text-underline-offset: 30%;
    color: white;
  }

  /* Hide the link that should open and close the topnav on small screens */
  nav .topnav .icon {
    display: none;
  }
}

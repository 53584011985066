.hamburger {
    cursor: pointer;
    margin-left: auto;
  }


.bar1, .bar2, .bar3 {
    width: 25px;
    height: 3px;
    background-color: #f2f2f2;
    margin: 5px 0;
    transition: 0.4s;
  }
  
  .folded.bar1 {
    -webkit-transform: rotate(-45deg) translate(-6px, 6px);
    transform: rotate(-45deg) translate(-6px, 6px);
  }
  
  .folded.bar2 {opacity: 0;}
  
  .folded.bar3 {
    -webkit-transform: rotate(45deg) translate(-6px, -6px);
    transform: rotate(45deg) translate(-6px, -6px);
  }
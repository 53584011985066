header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: row;
  
  font-size: calc(10px + 2vmin);
  color: white;
}

header #aone_logo {
  margin: 10px 0px 0px 0px;
  flex-grow: 1;
}
